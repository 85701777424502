"use strict";
/**
 * @typedef IExperiment
 * @property {string} key The key that is used to identify the experiment internally
 * @property {string} name The user-visible name for experiment, which will be displayed in the Users page.
 * @property {string} description A description for the experiment, that will be displayed in the Users page.
 * @property {boolean} default A {@link boolean} indicating whether or not this experiment is enabled by default.
 * @property {boolean} internal If true, the experiment is only visible in our development environments
 * (will not appear in any customer-facing environment)
 */


/**
 * Exposes the experiments that are available in the system.
 * @enum {IExperiment}
 */
const EXPERIMENTS = {
  PartyMode: {
    key: "PartyMode",
    name: "Party Mode",
    description: "Turn QbDVision into a super fun party!",
    default: false,
  },
  Menta: {
    key: "Menta",
    name: "Menta",
    description: "An AI chat interface for getting answers to pharmaceutical manufacturing questions.",
    default: true,
  },
  ObligatoryCQA: {
    key: "ObligatoryCQA",
    name: "Obligatory CQA",
    description: "Enable Obligatory CQA on FQA. This feature requires additional implementation.",
    internal: true,
    disabled: true,
    default: false,
  },
  BulkDocumentPreview: {
    key: "BulkDocumentPreview",
    name: "Document Preview in Bulk Operations",
    description: "Enable document preview in bulk operations",
    default: false,
  },
  Translation: {
    key: "Translation",
    name: "Translation",
    description: "Enable translation for QbDVision",
    default: false,
  },
  DeveloperTools: {
    key: "DeveloperTools",
    name: "Developer Tools",
    description: "Enable a set of tools that help developers with testing",
    default: false,
    internal: true,
  },
  BulkEdit: {
    key: "BulkEdit",
    name: "Bulk Edit",
    description: "Allow the users to edit table records in a bulk",
    default: false,
  },
};

module.exports = {
  EXPERIMENTS,
};
